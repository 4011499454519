/* eslint-disable */ 
import axios from 'axios';
// import { API_URL } from '@/constants/urls.js';

axios.defaults.headers.post['Content-Type'] = 'application/json'

// const service = axios.create({
//     baseURL: API_URL
// })

export const getProducts = async () => {
    return await axios({
        method: 'post',
        url: b9CartAjax.ajaxurl,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
            action: 'getProductsNonce',
            nonce: b9CartAjax.getProductsNonce
        },
    }).then(function (response) {
        return response.data
    });
}

export const editProductQuantity = async (hash, quantity) => {
    return await axios({
        method: 'post',
        url: b9CartAjax.ajaxurl,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
            action: 'editProductQuantityNonce',
            nonce: b9CartAjax.editProductQuantityNonce,
            hash: hash,
            quantity: quantity
        }
    });
}

export const deleteProduct = async (hash) => {
    return await axios({
        method: 'post',
        url: b9CartAjax.ajaxurl,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
            action: 'deleteProductNonce',
            nonce: b9CartAjax.deleteProductNonce,
            hash: hash
        }
    });
}

export const getTotals = async () => {
    return await axios({
        method: 'post',
        url: b9CartAjax.ajaxurl,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
            action: 'getTotalsNonce',
            nonce: b9CartAjax.getTotalsNonce
        }
    }).then((response) => {
        if (response.data.status) {
            return response.data.data
        }
        return []
    });
}

export const getCrossSellProducts = async () => {
    return await axios({
        method: 'post',
        url: b9CartAjax.ajaxurl,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
            action: 'getCrossSellProductsNonce',
            nonce: b9CartAjax.getCrossSellProductsNonce
        },
    }).then(function (response) {
        return response.data
    });
}

export const addToCartAPI = async (data) => {
    return await axios({
        method: 'post',
        url: b9CartAjax.ajaxurl,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
            action: 'addToCartNonceNew',
            nonce: b9CartAjax.addToCartNonceNew,
            product_id: data.product_id,
            variations: data.variations,
            quantity: data.quantity
        },
    }).then(function (response) {
        return response.data
    });
}