<template>
  <div>
    <div :class="drawerState ? 'right-drawer active' : 'right-drawer'">
      <div class="inside_content">
        <div class="top_head"> 
          <h1>YOUR CART</h1>

          <button class="close" @click="toggle()">
            <i class="fa fa-times" style="font-size:24px"></i>
          </button>
        </div>

        <Products />
        <CrossSellProducts />
        <Totals />
      </div>
    </div>

    <div class="drawer-mask" @click="toggle()"
      :style="{
          width: drawerState ? '100vw' : '0',
          opacity: drawerState ? '0.6' : '0',
      }"></div>
  </div>
</template>

<script setup>
import Products from './Products.vue'
import Totals from './Totals.vue'
import CrossSellProducts from './CrossSellProducts.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

function toggle(){
  store.dispatch('settings/updateDrawerOpen', !store.state.settings.drawerOpen)
}

const drawerState = computed(() => {
  return store.state.settings.drawerOpen
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
