<template>
    <div class="table">
      <div class="row">
        <div class="cell pro_img_resp"><div class="product_img"><img :src="item.image"></div></div>
        <div class="cell product_name w-60">{{ item.title }}
          <span class="pro_var" v-show="item.variations !== ''">{{ item.variations }}</span>
          <div class="product_price">
          <span class="pro_reg" v-if="item.has_sale_price">Regular: {{ item.regular_price }}</span>
          <span v-if="item.has_sale_price">Now </span>{{ item.sale_price }}
        </div>
        </div>
        <button @click="removeProduct" class="remove_product"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
        <div class="cell price_quan">
          <div class="quantity-field" >
            <button class="value-button decrease-button" @click="updateQuantity('minus')">-</button>
            <div class="number">{{ item.quantity_in_cart }}</div>
            <button class="value-button increase-button" @click="updateQuantity('plus')">+</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { editProductQuantity, deleteProduct } from '@/tools/api.js'
import { useStore } from 'vuex'
import { refreshCart, sendEmit } from '../tools/helpers.js'
import { getCrossSellProducts } from '@/tools/api.js'

const store = useStore()

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const quantity = ref(props.item.quantity_in_cart)

const updateQuantity = (operand) => {
  if (operand === 'plus') {
    quantity.value = props.item.quantity_in_cart + 1

  } else {
    if (quantity.value >= 2) {
      quantity.value = props.item.quantity_in_cart - 1
    }
  }

  editProductQuantity(props.item.key, quantity.value).then(() => {
    sendEmit()
    refreshCart(store)
  })
}

const removeProduct = () => {
  deleteProduct(props.item.key).then(() => {
    sendEmit()
    refreshCart(store)

    getCrossSellProducts().then((response) => {
      store.dispatch('upsell/updateProducts', response)
    })
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
