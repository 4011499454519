<template>
    <div>
        <p class="cart-notice">Shipping & taxes calculated at checkout</p>

        <div v-show="!loading">
            <div class="cart_total" v-for="(total, key) in totals" v-bind:key="key" v-show="total.label !== 'Shipping' && total.label !== 'Coupons' && total.label !== 'Total'">
                <TotalItem :item="total" />
            </div>
        </div>

        <PuSkeleton width="100%" height="30px" class="mt-15" v-show="loading" />
        <PuSkeleton width="100%" height="30px" class="mt-15" v-show="loading" />

        <div class="checkout_btn">
            <a @click.stop="goToCheckout">Checkout<i class="fa fa-shopping-cart"></i></a>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */ 
import { computed } from 'vue'
import { useStore } from 'vuex'

import TotalItem from './TotalItem.vue'

const store = useStore()

const totals = computed(() => { return store.state.totals.totals })
const loading = computed(() => { return store.state.settings.totalsLoading })

const goToCheckout = () => {
    store.dispatch('settings/updateDrawerOpen', false)
    window.location.href = b9CartAjax.checkoutUrl
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mt-15 {
  display: block;
  margin-bottom: .5rem;
}
</style>
