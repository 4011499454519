<template>
  <button class="cart_icon_awsm" type="button" @click="toggle()">
    <span class="count_items">{{ count }}</span>
    <i class="fa fa-shopping-cart"></i>
  </button>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

function toggle(){
  store.dispatch('settings/updateDrawerOpen', !store.state.settings.drawerOpen)
}

const count = computed(() => { return store.state.products.products.length })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
