/* eslint-disable */
export const UPDATE_DRAWER_OPEN = (state, data) => {
    state.drawerOpen = data
}
export const UPDATE_PRODUCTS_LOADING = (state, data) => {
    state.productsLoading = data
}
export const UPDATE_TOTALS_LOADING = (state, data) => {
    state.totalsLoading = data
}
