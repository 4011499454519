<template>
  <div :class="drawerState && products.length > 0 ? 'cross-sell-products active' : 'cross-sell-products'">
    <h4 class="text-center mb-5 main-title" @click="showCrossSellProducts">
      Got everything?
      <span>Do you need...</span>
      <i :class="`d-block d-lg-none fa ${visible ? 'fa-minus' : 'fa-plus'}`" aria-hidden="true"></i>
    </h4>

    <div :class="`${visible ? 'show-mobile' : ''} crossSellWrapper`">
      <div class="cross-sell-products-item" v-for="(product, index) in products" v-bind:key="index">
        <CrossSellProductItem
          :product="product"
        />
      </div>
    </div>

  </div>
</template>

<script setup>
import CrossSellProductItem from './CrossSellProductItem.vue'
import { getCrossSellProducts } from '@/tools/api.js'
import { computed, watch, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const drawerState = computed(() => {
  return store.state.settings.drawerOpen
})

const products = computed(() => {
  return store.state.upsell.products
})

watch(drawerState, (newValue) => {
  if (newValue) {
    getCrossSellProducts().then((response) => {
      store.dispatch('upsell/updateProducts', response)
    })
  }
})

const visible = ref(false)
const showCrossSellProducts = () => {
  visible.value = !visible.value
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h4 {
  @media screen and (max-width: 992px) {
    display: flex; /* Enable flexbox layout */
    justify-content: space-between; /* Distribute child elements */
  }

  i {
    @media screen and (max-width: 992px) {
      font-size: 1em;
    }
  }

  span {
    @media screen and (max-width: 992px) {
      display: none !important;
    }
  }
}
.crossSellWrapper {
  @media screen and (max-width: 992px) {
    height: 0;
  }

  &.show-mobile {
    @media screen and (max-width: 992px) {
      height: auto;
      margin-top: 15px;
    }
  }
}

</style>
