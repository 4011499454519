import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import totals from './totals'
import products from './products'
import settings from './settings'
import upsell from './upsell'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

const store = createStore({
  modules: {
    totals,
    settings,
    products,
    upsell
  },
  plugins: [vuexLocal.plugin],
  state: {},
  actions: {
    
  },
  mutations: {
    
  },
  // enable strict mode (adds overhead!)
  // for dev mode and --debug builds only
  strict: false
})

export default store
// export default Store(function (/* { ssrContext } */) {
//   return Store
// })
