import { getProducts, getTotals } from '../tools/api.js'

export const refreshCart = async (store) => {
    // Set loading state true for skeleton loading
    store.dispatch('settings/updateProductsLoading', true)
    store.dispatch('settings/updateTotalsLoading', true)
    
    // refresh products
    getProducts().then((response) => {
        store.dispatch('products/updateProducts', response)
        store.dispatch('settings/updateProductsLoading', false)
    })

    // refresh totals
    getTotals().then((totals) => {
        store.dispatch('totals/updateTotals', totals)
        store.dispatch('settings/updateTotalsLoading', false)
    })
}

export const sendEmit = () => {
    window.top.postMessage({
        refreshCheckout: true
    }, '*');
}