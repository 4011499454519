/* eslint-disable */ 
import { createApp } from 'vue'
import store from '@/store'
import App from './App.vue'
import Skeleton from 'vue-loading-skeleton'
import "vue-loading-skeleton/dist/style.css"
import ToastPlugin from 'vue-toast-notification'
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css'
import 'vue-toast-notification/dist/theme-bootstrap.css'

createApp(App)
.use(b9CartAjax)
.use(Skeleton)
.use(store)
.use(ToastPlugin)
.mount('#b9-cart')