<template>
  <div class="product-item mb-5 pb-5">
    <img class="" :src="product.image" :alt="product.title">

    <h6 class="mt-3 mb-1">{{ product.title }}</h6>

    <p :class="`${product.is_on_sale ? 'has-sale' : ''} regular-price mb-3`">
      <span class="regular">{{ product.regular_price }}</span>
      <span class="sale">{{ product.sale_price }}</span>
    </p>

    <div class="product-variations" v-if="product.variationsAttributes">
      <div v-for="(attributeValues, attributeKey) in product.variationsAttributes" v-bind:key="attributeKey">
        <label for="" v-if="attributeKey === 'attribute_size'">Size</label>
        <label for="" v-else-if="attributeKey === 'attribute_colour'">Colour</label>
        <label for="" v-else>{{ attributeKey }}</label>
        <select :name="attributeKey" id="" v-model="selectedValues[attributeKey]">
          <option v-for="attributeValue in attributeValues" :value="attributeValue" v-bind:key="attributeValue">{{ attributeValue }}</option>
        </select>
      </div>
    </div>

    <div class="quantity-field">
      <button :disabled="loading" :class="`${loading ? 'loading' : ''} add-to-cart`" @click="addToCart()" v-html="loading ? 'LOADING...' : 'ADD TO CART'"></button>

      <button class="value-button decrease-button" @click="updateQuantity('minus')">-</button>
      <div class="number">{{ quantity }}</div>
      <button class="value-button increase-button" @click="updateQuantity('plus')">+</button>
    </div>
  </div>

</template>

<script setup>
import { addToCartAPI } from '@/tools/api.js'
import { ref, onMounted } from 'vue'
import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import { refreshCart, sendEmit } from '../tools/helpers.js'
import { useStore } from 'vuex'

const store = useStore()
const toast = useToast()

const props = defineProps({
  product: {
    type: Object,
    required: true
  }
})

const loading = ref(false)
const addToCart = () => {
  let status = true
  loading.value = true

  // Check if variations are selected
  for (const x in props.product.variationsAttributes) {
    if (typeof selectedValues.value[x] === 'undefined') {
      status = false
    } else {
      if (selectedValues.value[x] === 'Choose an option') {
        status = false
      }
    }
  }

  if (!status) {
    // handle error
    toast.open({
      type: 'error',
      message: 'Please select all available variations.',
    })
    loading.value = false

    return
  }

  // Add to cart
  addToCartAPI({
    product_id: props.product.id,
    quantity: quantity.value,
    variations: selectedValues.value
  }).then((response) => {
    loading.value = false

    toast.open({
      type: response.status ? 'success' : 'error',
      message: response.message,
    })

    if (response.status) {
      sendEmit()
      refreshCart(store)
    }
  })
}

// Variations
const selectedValues = ref({})
onMounted(() => {
  for (const x in props.product.variationsAttributes) {
    selectedValues.value[x] = 'Choose an option'
  }
})

// Quantity
const quantity = ref(1)

const updateQuantity = (operand) => {
  if (operand === 'plus') {
    quantity.value++

  } else {
    if (quantity.value >= 2) {
      quantity.value--
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
