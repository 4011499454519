<template>
  <div>
    <CartIcon />
    <CartDrawer />
  </div>
</template>

<script setup>
/* eslint-disable */
import CartIcon from './components/CartIcon.vue'
import CartDrawer from './components/CartDrawer.vue'

import { useStore } from 'vuex'
import { refreshCart } from './tools/helpers.js'

const store = useStore()

// Add an event listener to the target window or iframe to receive the message
window.addEventListener('message', function(event) {
  // Access the received message data
  const receivedData = event.data;

  if (typeof receivedData.refreshCart !== 'undefined' && receivedData.refreshCart) {
    refreshCart(store)
  }
});
</script>

<style lang="scss">
#app {

}
</style>
