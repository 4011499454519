<template>
    <div>
        <div class="cart_product_table" v-for="product in products" v-bind:key="product.id" v-show="!loading">
            <ProductItem  @cart-updated="cartUpdated()" :item="product" />
        </div>
        <PuSkeleton width="100%" height="30px" class="mb-15" v-show="loading" style="margin-top: 50px" />
        <PuSkeleton width="100%" height="30px" class="mb-15" v-show="loading" />
        <PuSkeleton width="100%" height="30px" class="mb-15" v-show="loading" />
        <p class="empty-cart-text" v-show="!products.length && !loading">Your cart is empty.</p>
    </div>
</template>

<script setup>
/* eslint-disable */
import ProductItem from './ProductItem.vue'
import { onMounted, ref, computed } from 'vue'
import { refreshCart, sendEmit } from '../tools/helpers.js'
import { useStore } from 'vuex'
const store = useStore()


const products = computed(() => { return store.state.products.products })
const loading = computed(() => { return store.state.settings.productsLoading })


onMounted(async () => {
  refreshCart(store)
  sendEmit()

  // https://github.com/woocommerce/woocommerce/tree/trunk/plugins/woocommerce/client/legacy/js/frontend
  // https://github.com/woocommerce/woocommerce/blob/trunk/plugins/woocommerce/client/legacy/js/frontend/add-to-cart.js#L21
  jQuery( document.body ).on( 'added_to_cart removed_from_cart', function(){
    (async () => {
      refreshCart(store)
      sendEmit()
      //console.log('opening drawer');
      //store.state.settings.drawerOpen = true
      store.dispatch('settings/updateDrawerOpen', true)
    })();
  });
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mb-15 {
  display: block;
  margin-bottom: .5rem;
}
</style>
